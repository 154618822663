import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import SanityTexte from "../components/SanityTexte";
import SanityImage from "../components/SanityImage";
import LinkFadeRight from "../components/ui/TransitionLinks/LinkFadeRight";
import LinkFadeLeft from "../components/ui/TransitionLinks/LinkFadeLeft";
import { _linkResolver } from "../core/utils";

export const query = graphql`
  query WorkBySlug($slug: String!) {
    sanityWork(slug: { current: { eq: $slug } }) {
      id
      _id
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }

      slug {
        current
      }
      title
      texte: _rawTexte(resolveReferences: { maxDepth: 10 })
      images: _rawImages(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Work = ({ data, pageContext }) => {
  // console.log(pageContext);
  const {
    pagination: { previous, next },
  } = pageContext;
  const { seo, slug, title, texte, images } = data.sanityWork;
  const _images = images.filter((el) => el.asset !== undefined);

  return (
    <div className='main-area px-_md md:px-md'>
      {seo && (
        <Seo
          pageTitle={seo.metaTitle}
          pageDescription={seo.metaDescription}
          pageImage={seo.metaImage?.asset.url}
          template={`template-work page-${slug.current}`}
          page={true}
        />
      )}

      <div className='grid md:grid-cols-2 md:gap-md'>
        <div className='group mb-md z-10'>
          <div className='md:flex md:flex-col md:justify-between h-main sticky top-xl'>
            <div className='header'>
              <h1 className='mb-md'>{title}</h1>
              <SanityTexte input={texte} />
            </div>
            <div className='footer p-sm md:px-0 md:py-md fixed bottom-0 left-0 z-10 md:static bg-white md:bg-transparent w-full lowercase'>
              <div className='pagination flex'>
                {previous && (
                  <LinkFadeRight to={_linkResolver(previous)}>
                    PREV
                  </LinkFadeRight>
                )}
                {next && <span>/</span>}
                {next && (
                  <LinkFadeLeft to={_linkResolver(next)}>NEXT</LinkFadeLeft>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='group grid md:grid-cols-2 gap-_sm md:gap-sm'>
          {_images &&
            _images.length > 0 &&
            _images.map((image, i) => (
              <SanityImage
                key={i}
                input={image}
                showPlaceholder={false}
                objectFit='cover'
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
